<template>
    <div class="global-page-back">
        <div class="back-wrapper"
            @click="handlerGlobalType"
        >
            <span class="global-page-b">
                <img
                    class="global-page-icon"
                    :src="globalBack"
                    alt="global-page-back"
                />
            </span>
            <text-tooltip
                v-if="detailPageTitle"
                :content="detailPageTitle"
                class="current-position"
            ></text-tooltip>
        </div>
        <div id="J_global-page-back-tabs" class="tab-list">
            <slot name="globalPageBackTabs"></slot>
        </div>
        <div class="global-page-right">
            <slot name="globalPageBackRight"></slot>
        </div>
    </div>
</template>
<script>
import { TextTooltip } from "common-local";

export default {
    name: "GlobalPageBack",
    components: { TextTooltip },
    props: {
        detailPageTitle: String
    },
    computed: {
        globalBack () {
            return require('@/assets/images/global-back.png')
        }
    },
    methods: {
        handlerGlobalType() {
            this.$emit("handlerGlobalType");
        },
    },
};
</script>
<style lang="scss" scoped>
.global-page-back {
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 14px;
    background-color: #ffffff;
    border-radius: 4px 0px 0px 4px;
    .back-wrapper {
        display: flex;
        align-items: center;
        .global-page-b {
            display: inline-block;
            width: 29px;
            height: 29px;
            background: #FFFFFF;
            box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.17);
            border-radius: 50%;
            cursor: pointer;
            .global-page-icon {
                display: inline-block;
                width: 14px;
                height: 14px;
                margin: 7.5px 0 0 7.5px;
                background-color: #FFFFFF;
            }
        }

        .current-position {
            max-width: 200px;
            padding-left: 16px;
            font-size: 15px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            cursor: pointer;
            white-space: nowrap;
        }
    }
    .tab-list {
        height: 100%;
        display: flex;
        align-items: center;
        flex: 1;
    }
}
</style>
